.header
{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 70px;
    background-color: #ffffff;

    font-family: Verdana;
    Font-style: Bold;
    font-size: 14px;
    font-weight: 700;
    z-index: 2;
}

.h1
{
    position: fixed;
    top: 5px;
    left: 24px;
    scale: 85%;
}

.h2
{
    position: fixed;
    top: 11px;
    left: 85px;
    font-size: 22px;
}

.h3
{
    position: fixed;
    top: 40px;
    left: 85px;
}
