.s1
{
    position: fixed;
    width: 400px;
    height: 42px;
    top: 12px;
    left: 462px;
    background-color: #F3F4F6;
    
    color: #757575;
    font-family: Verdana;
    Font-style: Bold;
    font-size: 14px;
    font-weight: 700;
    text-align:initial;
    padding-left: 50px;

    border-radius: 50px;
    border-color: #757575;
    border-width: 0.2px;
}

.s1:focus
{
    outline: 0;
}

.s2
{
    position: absolute;
    top: 20px;
    left: 472px;
}
