body
{
    padding: 0px;
}

.box1
{
    background-color: #F3F4F6; 
    top: 70px;
    left: 0px;
    width: 100%;
    height: 300px;
}

.box1_text1
{
    top: 100px;
    left: 550px;
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    color: #1d1d1d;
}

.box1_text2
{
    top: 160px;
    left: 300px;
    right: 300px;
    position: absolute;
    font-size: 20px;
    color: #1d1d1d;
}

.box2
{
    background-color: #ffffff;
    top: 370px;
    left: 0px;
    width: 100%;
    height: 350px; 
    color: #1d1d1d;
}

.box2_text1
{
    top: 340px;
    left: 550px;
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    color: #1d1d1d;
}

.box2_text2
{
    top: 400px;
    left: 250px;
    right: 250px;
    position: absolute;
    font-size: 20px;
    color: #1d1d1d;
}

.box3
{
    background-color: #F3F4F6; 
    top: 670px;
    left: 0px;
    width: 100%;
    height: 370px; 
}

.box3_text1
{
    top: 670px;
    left: 550px;
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    color: #1d1d1d;
}

.box3_text2
{
    top: 730px;
    left: 100px;
    right: 100px;
    position: absolute;
    font-size: 20px;
    color: #1d1d1d;
}

.box4
{
    background-color: #ffffff;
    top: 1020px;
    left: 0px;
    width: 100%;
    height: 350px; 
    color: #1d1d1d;
}
.box4_text1

{
    top: 1040px;
    left: 550px;
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    color: #1d1d1d;
}

.box4_text2
{
    top: 1090px;
    left: 100px;
    right: 100px;
    position: absolute;
    font-size: 20px;
    color: #1d1d1d;
}