table 
{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: 80%;
  margin: auto;
  margin: 100px;
  border-collapse: collapse;
}
  
table td, table th 
{
  border: 1px solid #ddd;
  z-index: 2;
  padding: 8px;
}
  
table tr:nth-child(even)
{
  background-color: #ffffff;
}
  
table tr:hover 
{
  background-color: #ddd;
}
  
table th 
{
  text-align: center;
  background-color: #0071E3;
  color: #ffffff;
  padding-top: 12px;
  padding-bottom: 12px;
}