.menu
{
    position: fixed;
    width: 145px;
    height: 40px;
    left: 930px;
    top: 14px;

    border-style: solid;
    border-width: 0.2px;
    border-color: #757575;
    border-radius: 50px;

    font-family: Verdana;
    Font-style: Bold;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #757575;
    background-color: #F3F4F6;
}

.menu:hover
{
    cursor: pointer;
}

.menu.active
{
    position: fixed;
    width: 145px;
    height: 40px;
    left: 930px;
    top: 14px;

    border-style: solid;
    border-width: 0.2px;
    border-color: #757575;
    border-radius: 50px;

    font-family: Verdana;
    Font-style: Bold;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #757575;
    background-color: #F3F4F6;
}

.menu.inactive
{
    position: fixed;
    width: 145px;
    height: 40px;
    left: 930px;
    top: 14px;

    border-radius: 50px;
    border-style: solid;
    border-width: 0.2px;
    border-color: #757575;

    font-family: Verdana;
    Font-style: Bold;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #757575;
    background-color: #F3F4F6;
}

.sor1
{
   position: fixed;
   left: 945px;
   top: 25px;;
   z-index: 2;
   scale: 85%;
}

.sor2
{
    position: fixed;
    left: 985px;
    top: 27px;
    z-index: 2;
}

.dropdown-menu.active
{
    left: 930px;
    position: fixed;
    margin-top: 45px;
    width: 146px;
    padding-left: 0px;

    border: #757575;
    border-style: solid;
    border-width: 0.2px;
    
    opacity: 1;
    visibility: visible;
}

.dropdown-menu.inactive
{
    left: 930px;
    position: fixed;
    margin-top: 45px;
    width: 146px;
    
    opacity: 0;
    visibility: hidden;
}

.dropdown-item
{
    font-family: Verdana;
    list-style-type: none;
    height: 30px;


    background-color: #F3F4F6;
    text-align: center;
    padding-top: 10px;
}

.dropdown-item:hover
{
    background-color: #0071E3;
    color: #ffffff;
    cursor: pointer;
}

