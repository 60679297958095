.footer
{
    position:fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 70px;
    background-color: #ffffff;

    font-family: Verdana;
    Font-style: Bold;
    font-size: 14px;
    font-weight: 700;
    z-index: 2;
}

.f1
{
    position: absolute;
    left: 10px;
    bottom: 30px;
}

.f2
{
    position: absolute;
    left: 284px;
    bottom: 30px;
}

.f3
{
    position: absolute;
    left: 1010px;
    bottom: 30px;
    color: #1D1D1D;
}

.f3:hover 
{
    background-color: #0071E3;
    color:#ffffff;
}

.f4
{
    position: absolute;
    left: 1200px;
    bottom: 30px;
    color: #1D1D1D;
}

.f4:hover 
{
    background-color: #0071E3;
    color:#ffffff;
}