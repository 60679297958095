.reviewbutton
{
    font-family: Verdana;
    Font-style: Bold;
    font-size: 14px;
    font-weight: 700;

    background-color: #0071E3;
    color: #ffffff;
    border-radius: 50px;
    border-width: 0.2px;
    border-color: #757575;

    position: fixed;
    width: 140px;
    height: 40px;
    right: 24px;
    top: 14px;
    text-align: center;
}

.reviewbutton:hover
{
    cursor: pointer;
    background-color: #0269d0;
}

.reviewform
{
    font-family: Verdana;
    Font-style: Bold;
    font-size: 14px;
    font-weight: 700;
    text-align: center;

    color: #757575;
    background-color: #F3F4F6;
    border-width: 0.2px;
    border-color: #757575;
    border-style: solid;

    position: fixed;
    width: 400px;
    height: 225px;
    right: 24px;
    top: 60px;
    text-align: center;

}

.reviewformh
{
    font-family: Verdana;
    Font-style: Bold;
    font-size: 14px;
    font-weight: 700;
    text-align: center; 
    color: #1D1D1D;
}

.submitreviewbutton
{
    font-family: Verdana;
    Font-style: Bold;
    font-size: 14px;
    font-weight: 700;

    background-color: #0071E3;
    color: #ffffff;
    border-radius: 50px;
    border-width: 0.2px;
    border-color: #757575;

    position: fixed;
    width: 140px;
    height: 30px;
    right: 150px;
    top: 240px;
    text-align: center;
}

.submitreviewbutton:hover
{
    cursor: pointer;
    background-color: #0269d0;
}