.indicator
{
    position: fixed;
    width: 145px;
    height: 40px;
    left: 1060px;
    top: 14px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;

    border-style: solid;
    border-width: 0.2px;
    border-color: #757575;

    font-family: Verdana;
    Font-style: Bold;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #F3F4F6;
    background-color: #0071E3;
    z-index: -2;
}

.indicator_text
{
    left: 1080px;
    top: 27px;
    position: fixed;
    text-align: center;
}